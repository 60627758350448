import React from "react";
import { Link as ScrollLink } from "react-scroll";

const herosectionData = {
  name: "Sifi My",
  aboutMe:
    "This is the official site of Sifi My, currently we are having a lot of changes converting from a typical website to a web application. Sifi My web application committed to share to the world theese defination:",
};

function Herosection() {
  return (
    <section
      className="hero background parallax shadow-dark d-flex align-items-center"
      style={{ backgroundImage: "url(/images/hero.jpg)" }}
    >
      <div className="cta mx-auto mt-2">
        <h1 className="mt-0 mb-4">
          Official {herosectionData.name}
          <span className="dot"></span>
        </h1>
        <p className="mb-4">{herosectionData.aboutMe}</p>
        <ScrollLink
          activeClass="active"
          to="section-portfolios"
          spy={true}
          smooth={true}
          duration={500}
          offset={50}
          className="btn btn-default btn-lg mr-3"
        >
          <i className="icon-grid"></i>
          <a href="/">Succes is For ' I '</a>
        </ScrollLink>
        <br />
        <br />
        <ScrollLink
          activeClass="active"
          to="section-portfolios"
          spy={true}
          smooth={true}
          duration={500}
          offset={50}
          className="btn btn-default btn-lg mr-3"
        >
          <i className="icon-grid"></i>
          <a href="/">Software Integration For Intermediate</a>
        </ScrollLink>
        <br />
      </div>

      <div className="overlay"></div>
    </section>
  );
}

export default Herosection;
