import React from "react";
import { Link as ScrollLink } from "react-scroll";

const aboutData = {
  cvpath: "media/empty.pdf",
  image: "images/about.png",
  name: "Sifi My",
  location: "Kuala Lumpur, Malaysia",
  whatsapp: "+601136500988",
  email: "hr@sifi.my",
  aboutMe:
    "This site is actually dedicated to Ha--Sifi a.k.a The Chief. Ever since he was a child, he has a wise thoughts, skillful hand and a golden heart. Eventhough he said his little sister is the initiation call for why he was call Cip, that itself proves of his golden heart. Even science approves his profile as 6-3 base on human design research. He contributes a lot in the world of 'Online Brokers' and softwares.  Many saw him as easy going, and everything seems easy to him like his career as Broker and a Programmer. The truth only he knows. ",
};

function About() {
  return (
    <div className="row">
      <div className="col-md-3">
        <img src={aboutData.image} alt={aboutData.name} />
      </div>
      <div className="col-md-9">
        <h2 className="mt-4 mt-md-0 mb-4">Hello,</h2>
        <p className="mb-0">{aboutData.aboutMe}</p>
        <div className="row my-4">
          {/* <div className="col-md-6">
            <p className="mb-2">
              Name: <span className="text-dark">{aboutData.name}</span>
            </p>
            <p className="mb-0">
              Whatsapp: <span className="text-dark">{aboutData.whatsapp}</span>
            </p>
          </div>
          <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
            <p className="mb-2">
              Location: <span className="text-dark">{aboutData.location}</span>
            </p>
            <p className="mb-0">
              Email: <span className="text-dark">{aboutData.email}</span>
            </p>
          </div> */}
        </div>
        {/* <a href={aboutData.cvpath} className="btn btn-default mr-3">
          <i className="icon-cloud-download"></i>Download CV
        </a>
        <ScrollLink
          activeClass="active"
          to="section-contact"
          spy={true}
          smooth={true}
          duration={500}
          offset={50}
          className="btn btn-alt mt-2 mt-md-0 mt-xs-2"
        >
          <i className="icon-envelope"></i>
          <a href="hr@sifi.my">Hire Me</a>
        </ScrollLink> */}
      </div>
    </div>
  );
}

export default About;
