"use client";
import React, { useEffect, useState  } from 'react';
import { useLocation , useRouteMatch } from 'react-router-dom';


function Adsense() {

  const { dataAdSlot } = useState();  

  const pathname = useLocation ();
  const searchParams = useRouteMatch();

  useEffect(() => {
    const url = `${pathname}?${searchParams}`;
    console.log("AdsenseComp -> router changed ", url);

    const scriptElement = document.querySelector(
      'script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2397523479633799"]'
    );

    const handleScriptLoad = () => {
      try {
        if (window.adsbygoogle) {

          console.log("pushing ads ");
          window.adsbygoogle.push({});
        } else {
          scriptElement.addEventListener("load", handleScriptLoad);
          console.log("waiting until adsense lib is loaded...This prevents adsbygoogle is not defined error");
        }
      } catch (err) {
        console.log("error in adsense - This prevents ad already exists error", err);
      }
    };
    handleScriptLoad();
     // Wait for script to load

     return () => {
      if (scriptElement) {
        scriptElement.removeEventListener("load", handleScriptLoad);
      }
    };
  }, [pathname, searchParams]);

    return (
      <section className="shadow-dark background-blue color-white padding">
        <h2 className="d-none">Ads</h2>
  
        <div className="clients-wrapper">

        {/* <ins  
          className="adsbygoogle"
          style="display:block;width:425px;height:45px"
          data-ad-client="ca-pub-2397523479633799"
          data-ad-slot="9243495626"
          data-ad-format="auto"
          data-full-width-responsive="true"
            
        ></ins> */}

        </div>
      </section>
    );
  }
  
  export default Adsense;
  